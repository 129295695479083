import { isObject, kebabCase } from "lodash";
import { createGlobalStyle, css } from "styled-components";

function createCssVariables(theme) {
  return Object.entries(theme)
    .filter(tuple => !isObject(tuple[1]))
    .map(([key, value]) => {
      return `--${kebabCase(key).replace(/-(\d+)/g, "$1")}: ${String(value)};`;
    })
    .join("\n");
}

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    ${props => createCssVariables(props.theme)}
    background-color: var(--main);
    color: var(--text);
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Helvetica Neue', sans-serif;
  }

  a {
    text-decoration: none;
    color: var(--text);

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    cursor: pointer;
  }

  #gatsby-focus-wrapper {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
`;

export default GlobalStyles;
