import styled from "styled-components";
import { MEDIA_QUERIES } from "../../config";

export const H1 = styled.h1`
  font-size: 56px;
  font-weight: bold;
  line-height: 1.14;
  margin: 0;

  ${MEDIA_QUERIES.mobile} {
    font-size: 32px;
  }
`;

export const H2 = styled.h2`
  font-size: 56px;
  font-weight: 700;
  line-height: 1.14;
  margin: 0;

  ${MEDIA_QUERIES.mobile} {
    font-size: 32px;
  }
`;

export const H3 = styled.h3`
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  margin: 0;

  ${MEDIA_QUERIES.mobile} {
    font-size: 18px;
  }
`;

export const H4 = styled.h4`
  font-size: 20px;
  line-height: 1.2;
  text-transform: uppercase;
  margin: 0;

  ${MEDIA_QUERIES.mobile} {
    font-size: 16px;
  }
`;

export const P = styled.p`
  font-size: 14px;
  line-height: 1.57;
  width: 100%;

  ${MEDIA_QUERIES.mobile} {
    font-size: 12px;
  }
`;
