import React from "react";
import Section from "./Section";
import IconLogo from "../../assets/icons/logo.svg";
import styled, { css } from "styled-components";
import { MEDIA_QUERIES } from "../../config";
import { slide as BurgerMenu } from "react-burger-menu";
import { MENUS } from "../../data";
import { useLocation } from "@reach/router";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "36px",
    height: "30px",
    right: "32px",
    top: "50px",
    zIndex: 998,
  },
  bmBurgerBars: {
    background: "#000",
  },
  bmBurgerBarsHover: {
    background: "#000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
    display: "flex",
  },
  bmCross: {
    background: "#fff",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    top: "0",
  },
  bmMenu: {
    background: "#000",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItem: {
    display: "inline-block",
    textTransform: "uppercase",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    top: "0",
    left: "0",
  },
};

const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
  min-width: 250px;
  background: black;
  padding: 16px;
  display: none;
  transition: opacity 200ms ease;

  li:not(:last-child) {
    margin-bottom: 10px;
  }

  a {
    color: white;
  }
`;

function Header() {
  const location = useLocation();

  const listItems = MENUS.map(menu => (
    <li key={menu.name}>
      {menu.to ? (
        <AnchorLink to={menu.to}>{menu.name}</AnchorLink>
      ) : menu.href ? (
        <a href={menu.href}>{menu.name}</a>
      ) : (
        menu.name
      )}

      {menu.items && (
        <Menu>
          {menu.items.map(item => (
            <li key={item.name}>
              {item.to ? (
                <AnchorLink to={item.to}>{item.name}</AnchorLink>
              ) : (
                <a href={item.href}>{item.name}</a>
              )}
            </li>
          ))}
        </Menu>
      )}
    </li>
  ));

  return (
    <Section
      as="header"
      css={`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 0;
        width: 100%;

        ${MEDIA_QUERIES.mobile} {
          padding: 40px 16px;
        }
      `}
    >
      <AnchorLink to="/">
        <IconLogo
          css={`
            ${location.pathname !== "/"
              ? css`
                  &:hover {
                    #circleLogo {
                      fill: var(--accent);
                    }
                  }
                `
              : ""}
          `}
        />
      </AnchorLink>

      <div
        css={`
          display: none;

          ${MEDIA_QUERIES.mobile} {
            display: block;
          }
        `}
      >
        <BurgerMenu right styles={styles}>
          <ul
            css={`
              outline: none;
              list-style: none;

              a {
                color: #fff;
              }

              li {
                color: #fff;
                position: relative;
                margin-bottom: 32px;

                ${Menu} {
                  all: unset;
                  position: static;

                  li {
                    padding-left: 16px;
                  }

                  li:first-child {
                    margin-top: 16px;
                  }
                }
              }
            `}
          >
            {listItems}
          </ul>
        </BurgerMenu>
      </div>

      <ul
        css={`
          display: flex;
          list-style: none;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.69px;
          line-height: 1.67;
          margin-left: auto;

          ${MEDIA_QUERIES.mobile} {
            display: none;
          }

          li {
            position: relative;
            &:hover {
              & > ${Menu} {
                display: block;
              }
            }
          }

          & > *:not(:last-child) {
            margin-right: 48px;
          }
        `}
      >
        {listItems}
      </ul>
    </Section>
  );
}

export default Header;
