import { Link } from "gatsby";
import React from "react";
import Section from "./Section";
import IconFacebook from "../../assets/icons/facebook.svg";
import IconInstagram from "../../assets/icons/instagram.svg";
import IconLogoSquare from "../../assets/icons/logo-square.svg";
import { MEDIA_QUERIES } from "../../config";

function Footer() {
  return (
    <footer
      css={`
        background: #000;
        --text: #fff;
      `}
    >
      <Section
        css={`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 56px 0;

          ${MEDIA_QUERIES.mobile} {
            flex-direction: column;

            & > *:not(:last-child) {
              margin-bottom: 24px;
            }
          }
        `}
      >
        <Link to="/">
          <IconLogoSquare />
        </Link>
        <ul
          css={`
            display: flex;
            list-style: none;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 1.57;
            margin: 0;
            padding: 0;

            & > *:not(:last-child) {
              margin-right: 48px;
            }

            ${MEDIA_QUERIES.mobile} {
              flex-direction: column;
              text-align: center;

              & > *:not(:last-child) {
                margin-right: unset;
                margin-bottom: 24px;
              }
            }
          `}
        >
          <li>
            <Link to="/contact">ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</Link>
          </li>
          <li>
            <Link to="/team">Η ΟΜΑΔΑ ΜΑΣ</Link>
          </li>
          <li>
            <Link to="/program">ΤΟ ΠΡΟΓΡΑΜΜΑ</Link>
          </li>
        </ul>
        <ul
          css={`
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;

            & > *:not(:last-child) {
              margin-right: 24px;
            }

            svg {
              fill: var(--text);
            }
          `}
        >
          <li>
            <a href="https://www.facebook.com/%CE%98%CE%BF%CE%B4%CF%89%CF%81%CE%AE%CF%82-%CE%A0%CE%B1%CE%BD%CE%AC%CF%82-%CE%A3%CF%87%CE%BF%CE%BB%CE%AE-%CE%A7%CE%BF%CF%81%CE%BF%CF%8D-%CE%91%CE%B8%CE%B7%CE%BD%CF%8E%CE%BD-Thodoris-Panas-Athens-Dance-School-1607955359472551/?ref=ts&fref=ts">
              <IconFacebook />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/thodorispanasathensdanceschool/">
              <IconInstagram />
            </a>
          </li>
        </ul>
      </Section>
    </footer>
  );
}

export default Footer;
