import styled from "styled-components";
import { MEDIA_QUERIES } from "../../config";
import { H1, P } from "./Typography";

const Section = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

const Main = styled.main`
  padding: 32px 0 72px 0;
  text-align: center;

  ${H1} {
    margin-bottom: 32px;
  }

  ${P} {
    margin-left: auto;
    margin-right: auto;
    max-width: 587px;
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 0 16px 32px 16px;
    text-align: left;

    ${H1} {
      margin: 32px 0;
    }
  }
`;

Section.Main = Main;

export default Section;
