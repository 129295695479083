import React from "react";
import { P } from "./components/common/Typography";
import imageHero1 from "./assets/images/hero-1.jpg";
import imageHero2 from "./assets/images/hero-2.jpg";
import imageHero3 from "./assets/images/hero-3.jpg";

export const TEACHERS = [
  {
    name: "ΘΟΔΩΡΗΣ ΠΑΝΑΣ",
    description: (
      <>
        <P>
          Στόχος του μαθήματος είναι να εμβαθύνει στην κίνηση και ερμηνεία του
          performer έτσι ώστε ο χορός να αποτελεί αναγκαίο κομμάτι του ρόλου.
          Χορός και τραγούδι γίνονται ένα.
        </P>
        <P>
          Η μέγιστη επιδίωξη μας είναι μέσα από το χορό να αφηγηθούμε ιστορίες
          προσωπικές και μη,όπου θα υπάρχει αλήθεια και κατανόηση για να μπορεί
          το κοινό να ταυτιστεί απόλυτα με τη στιγμή. Δίνουμε τη δυνατότητα
          στους χορευτές να αποκτήσουν την ικανότητα επίλυσης προβλημάτων, τους
          ενθαρρύνουμε στη γρήγορη σκέψη, ώστε να είναι δημιουργικοί και
          προσαρμοστικοί στις εκάστοτε συνθήκες, βελτιώνοντας την αυτοπεποίθησή
          τους μέσω της ομαδικής δουλειάς και της εμπιστοσύνης μεταξύ των μελών
          της ομάδας.
        </P>
        <P>
          Θα δουλέψουμε τεχνικές όπως: Τheatrical Jazz(Fosse - Chicago, Jerome
          Robbins - West Side Story, Jack Cole - The Father of Theatrical Jazz
          Dance, Michael Bennett - Chorus Line, Gillian Lynne - Cats κ.ά.),
          Lyric Jazz, Pop and Contemporary Dance, εργαλεία απαραίτητα για να
          γίνει κάποιος πολυδύναμος performer.
        </P>
      </>
    ),
  },
  {
    name: "ΔΗΜΗΤΡΗΣ ΜΑΛΙΣΣΟΒΑΣ",
    description: (
      <>
        <P>
          Το Musical είναι πια στη ζωή μας. Τα τελευταία χρόνια γίνονται αρκετές
          παραστάσεις στην Ελλάδα. Οι περισσότερες πετυχημένες.
        </P>
        <P>
          Σαν χώρα, είχαμε πάντα παράδοση στην Οπερέτα και το Μουσικό θέατρο, τη
          γνωστή σε όλους μας, Επιθεώρηση. Χορογράφοι, Ηθοποιοί, Μουσικοί αλλά
          και οι περισσότερες μουσικές επιτυχίες του προηγούμενου αιώνα
          ξεπήδησαν από την επιθεώρηση.
        </P>
        <P>
          Με τα χρόνια οι θεατές έδειξαν την αγάπη τους στο Musical κάνοντας τις
          απαιτήσεις των δημιουργών να μεγαλώνουν.
        </P>
        <P>Σήμερα το στοίχημα είναι ακόμα μεγαλύτερο.</P>
        <P>Το θέατρο έχει ανάγκη από ανθρώπους που να ξέρουν καλά το είδος.</P>
        <P>Θα πρέπει να τραγουδούν, να χορεύουν και να παίζουν εξίσου καλά.</P>
        <P>
          Σαν σκηνοθέτης, τολμώ να δουλεύω με νέους ανθρώπους και να μην
          καταφεύγω στην εύκολη λύση των γνωστών ηθοποιών που θα έκαναν
          εμπορικότερη την μαρκίζα.
        </P>
        <P>
          Στην σχολή μας, όλα αυτά τα χρόνια, έχουμε βγάλει στο θέατρο αρκετούς
          μαθητές μας και ήδη έχουν μια λαμπρή σταδιοδρομία, κυρίως στο μουσικό
          θέατρο. Μας κάνουν πολύ περήφανους, γιατί είναι σημαντικό να βλέπεις
          τους μαθητές σου - που έχεις μοιραστεί τις ανησυχίες και τα όνειρα
          τους - να προοδεύουν. Και είναι πολύ συγκινητικό να παίρνουν μέρος
          στις παραστάσεις σου…
        </P>
        <P>
          Η υποκριτική έχει μεγάλη σημασία για το Musical. Μόνο αν αφηγηθεί
          σωστά κανείς την ιστορία θα πείσει το κοινό. Μόνο αν περάσει η ιστορία
          κάτω στην πλατεία θα καταφέρει να συγκινήσει.
        </P>
        <P>
          Μαζί θα μάθουμε τους τρόπους και τις τεχνικές του λόγου σε απόλυτη
          συνάρτηση με το τραγούδι και τον χορό.
        </P>
        <P>
          Μα πολύ περισσότερο, θα μάθουμε την αλήθεια που κρύβεται πίσω από ένα
          κείμενο. Τον τρόπο να διαβάζουμε με διαφορετικό τρόπο έναν ρόλο. Τον
          τρόπο να μπορούμε να αναγνωρίσουμε, κάπου εκεί, τον εαυτό μας.
        </P>
        <P>Τον τρόπο να "γίνουμε" εμείς ο ρόλος και όχι να τον μιμηθούμε...</P>
        <P>Η πιο δύσκολη, η πιο επίπονη, μα τελικά η πιο ευχάριστη διαδρομή!</P>
        <P>Σημασία έχει πάντα το ταξίδι...</P>
      </>
    ),
  }, //  ,
  //   {
  //      name: "ΑΛΕΞΙΟΣ ΠΡΙΦΤΗΣ",
  //      description: (
  //        <>
  //          <P>
  //            Το μουσικό θέατρο συνδυάζει τη μουσική, το χορό και την υποκριτική.
  //            Κάθε μελωδία είτε πρόκειται για ολοκληρωμένο τραγούδι είτε για μελωδία
  //           που συνοδεύει μια σκηνή έχει το δικό της ρόλο και λόγο μέσα στο έργο.
  //          Είναι ένας επιπλέον ολοκληρωμένος χαρακτήρας.
  //      </P>
  //   <P>
  //      Το τραγούδι στο μουσικό θέατρο δεν είναι - και δεν πρέπει να είναι -
  //     το ευχάριστο μουσικό διάλειμμα. Έχει τη δική του υπόσταση, το δικό του
  //      λόγο ύπαρξης. Πρέπει να είναι η συνέχεια του πεζού λόγου.
  //     </P>
  //     <P>
  //       Όμως το κύριο ζήτημα δεν είναι μόνο να τραγουδάει κάποιος σωστά, να
  //       χορεύει σωστά ή να παίζει. Πρέπει να μάθει να τα συνδυάζει. Αυτό είναι
  //       το ζητούμενο στο μουσικό θέατρο. Αυτή είναι η μαγεία του.
  //    </P>
  //    <P>
  //      Η εμπειρία μας στο χώρο και η τεχνική μας κατάρτιση βοηθάνε όλους τους
  //      μαθητές να μάθουν τα μυστικά και τις τεχνικές γύρω από αυτό το
  //      φαντασμαγορικό κόσμο.
  //       </P>
  //     </>
  //     ),
  //   },
];

export const LESSONS = [
  {
    teacher: "ΘΟΔΩΡΗΣ ΠΑΝΑΣ",
    lessons: "THEATRICAL JAZZ",
  },
  // {
  //  teacher: "ΑΛΕΞΙΟΣ ΠΡΙΦΤΗΣ",
  //   lessons:
  //     "ΙΣΤΟΡΙΑ ΜΟΥΣΙΚΟΥ ΘΕΑΤΡΟΥ, ΤΕΧΝΙΚΗ ΑΚΡΟΑΣΗΣ, ΟΜΑΔΙΚΟ ΤΡΑΓΟΥΔΙ (Group Singing)",
  //  },
  {
    teacher: "ΔΗΜΗΤΡΗΣ ΜΑΛΙΣΣΟΒΑΣ",
    lessons: "ΥΠΟΚΡΙΤΙΚΗ",
  },
  {
    teacher: "ΜΑΡΙΑ ΓΡΑΜΨΑ",
    lessons: "ΦΩΝΗΤΙΚΗ, ΘΕΩΡΙΑ ΜΟΥΣΙΚΗΣ ΣΤΗΝ ΠΡΑΞΗ",
  },
  {
    teacher: "ΜΑΡΙΑΝΝΑ ΖΟΡΜΠΑ",
    lessons: "ΤΕΧΝΙΚΗ ΤΡΑΓΟΥΔΙΟΥ",
  },
  {
    teacher: "ΚΑΤΙΑ ΠΑΣΧΟΥ",
    lessons: "ΕΡΜΗΝΕΙΑ ΤΡΑΓΟΥΔΙΟΥ",
  },
  {
    teacher: "ΠΕΡΙΚΛΗΣ ΠΕΤΡΑΚΗΣ, VIRTUOZO",
    lessons: "HIP HOP (Freestyle and Choreo)",
  }, //,
  // {
  //   teacher: "ΑΙΜΙΛΙΑΝΟΣ ΣΤΑΜΑΤΑΚΗΣ",
  //   lessons: "ACTING THROUGH SONG",
  // },
  // {
  //   teacher: "ΜΑΡΙΑ ΣΑΡΑΝΤΟΠΟΥΛΟΥ",
  //   lessons: "ΜΠΑΛΕΤΟ",
  // },
  // {
  //   teacher: "ΚΩΝΣΤΑΝΤΙΝΟΣ ΚΑΤΣΟΥΔΑΣ",
  //   lessons: "ΣΥΓΧΡΟΝΟΣ ΧΟΡΟΣ, ΑΥΤΟΣΧΕΔΙΑΣΜΟΣ",
  // },
  // {
  //   teacher: "ΑΝΤΕΙΑ ΟΛΥΜΠΙΟΥ",
  //   lessons: "ΚΛΑΚΕΤΕΣ",
  // },
  // {
  // teacher: "ΘΑΝΟΣ ΔΑΣΚΑΛΟΠΟΥΛΟΣ",
  //lessons: "ΚΛΑΚΕΤΕΣ - TAP DANCING"
  //  }
];

export const HEROES = [
  {
    imageSrc: imageHero1,
    imageAlt: "Athens Dance School 1",
  },
  {
    imageSrc: imageHero2,
    imageAlt: "Athens Dance School 2",
  },
  {
    imageSrc: imageHero3,
    imageAlt: "Athens Dance School 3",
  },
];

export const ADULT_CLASSES = [
  {
    name: "Κλασικό Μπαλέτο",
  },
  {
    name: "Σύγχρονος χορός",
  },
  {
    name: "Commercial Jazz",
  },
  {
    name: "Express Yourself",
  },
  {
    name: "Latin",
  },
  {
    name: "Hip Hop",
  },
  {
    name: "Yoga",
  },
  {
    name: "Aerial",
  },
];

export const AGE_CLASSES = [
  {
    name: "'Εως 5 χρονών",
    list: [
      {
        name: "Μουσικοκινητική Αγωγή",
      },
      {
        name: "Commercial Jazz Babies",
      },
    ],
  },
  {
    name: "5-8 χρονών",
    list: [
      {
        name: "Κλασικό Μπαλέτο",
      },
      {
        name: "Commercial Jazz",
      },
      {
        name: "Musical Dance",
      },
      {
        name: "Musical / Μουσική Αγωγή",
      },
    ],
  },
  {
    name: "8-16 χρονών",
    list: [
      {
        name: "Μπαλέτο (Degre I - II - III) στο ρώσικο τμήμα, VAGANOVA",
      },
      {
        name: "Σύγχρονος χορός",
      },
      {
        name: "Αυτοσχεδιασμός",
      },
      {
        name: "Breakdance",
      },
      {
        name: "Commercial Jazz",
      },
      {
        name: "Hip Hop",
      },
      {
        name: "Musical / Μουσική Αγωγή",
      },
    ],
  },
];

export const MENUS = [
  {
    name: "Η σχολη μας",
    to: "/school",
    items: [
      {
        name: "Η ΦΙΛΟΣΟΦΙΑ ΜΑΣ",
        to: "/philosophy",
      },
      {
        name: "Η ΟΜΑΔΑ ΜΑΣ",
        to: "/team",
      },
      {
        name: "Η ΑΡΧΙΤΕΚΤΟΝΙΚΗ",
        to: "/architecture",
      },
      {
        name: "Royal Academy of Dance",
        to: "/royal-academy-of-dance",
      },
      {
        name: "ΕΝΟΙΚΙΑΣΗ ΧΩΡΟΥ",
        to: "/studios",
      },
      {
        name: "ΠΑΡΑΣΤΑΣΕΙΣ - ΣΕΜΙΝΑΡΙΑ",
        to: "/events",
      },
      {
        name: "Gallery",
        to: "/gallery",
      },
    ],
  },
  {
    name: "Προγραμμα",
    to: "/program",
  },
  {
    name: "Τα τμηματα μας",
    items: [
      {
        name: "ΤΜΗΜΑΤΑ ΕΝΗΛΙΚΩΝ",
        to: "/#adults",
      },
      {
        name: "ΠΑΙΔΙΚΑ ΤΜΗΜΑΤΑ",
        to: "/#children",
      },
      {
        name: "SUMMER DANCING",
        href: "/summer-dancing",
      },
    ],
  },
  {
    name: "Musical Theater",
    href: "/musical-theater",
  },
  {
    name: "Επικοινωνια",
    to: "/contact",
  },
];
