export const THEME = {
  main: "#ffffff",
  text: "#191919",
  accent: "#ede740",
};

export const DEVICES = {
  mobile: 540,
  tablet: 768,
  desktop: 1024,
};

export const MEDIA_QUERIES = {
  mobile: `@media (max-width: ${DEVICES.mobile}px)`,
  tablet: `@media (max-width: ${DEVICES.tablet}px)`,
  desktop: `@media (max-width: ${DEVICES.desktop}px)`,
};
