import React from "react";
import GlobalStyles from "./GlobalStyles";
import Header from "./Header";
import { THEME } from "../../config";
import Footer from "./Footer";

function Layout({ children }) {
  return (
    <>
      <GlobalStyles theme={THEME} />
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
